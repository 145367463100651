.allSetToJoinContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.allSetToJoinMessage {
    margin-bottom: 2rem;
}

.verticalMeetingContainer {
    display: flex;
    flex-direction: row;
    flex: 1;
}

.meetingContainer {
    position: relative;
    display: flex;
    flex: 1;
    
}

.localVideo {
    /* used aspect ratio calculator from https://calculateaspectratio.com/ */
    /* 16:9 aspect ratio */

    width: 20vw;
    height: fit-content;

    border-radius: 10px;
    box-sizing: border-box;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));


    /* position to the right;  */
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 10px;
    margin-top: 10px;
    overflow: hidden;
    &>div{
        height: fit-content !important;
        margin-bottom: -10px;
        video{
            object-fit: contain !important;
            height: auto !important;
        }
    }
    

}

.remoteVideo{
    flex: 1;
    width: 100vw;
    video{
        max-height: calc(100vh - 67px);
        object-fit: contain !important;
    }

    &>div{
        display: none;
        visibility: hidden;
        &:first-of-type{
            display: block;
            visibility: visible;
        }
    }
}

.controlButtonsContainer {
    position: absolute;
    bottom: 0;
    
    /* formula to center div in viewport */
    left: calc( (100vw - 340px) / 2);

    margin-bottom: 30px;
    
}

.joinControlButtonsContainer {
    
    display: flex;
    flex-direction: row;
    
    align-items: center;
    
    width: 330px;
    height: 108px;
    

    background: rgba(255, 255, 255, 0.41);
    border-radius: 20px;

}

/* Button Styles */
.cbButton {
    width: 62.71px;
    height: 62.71px;
    margin-left: 2.5rem;
    border-radius: 30px;
    border-style: solid;
    background: rgba(255, 255, 255, 1);
    border-color: rgba(255, 255, 255, 1);
    cursor: pointer;
    &.callButton {
        background: rgba(234, 51, 35, 1);
        border-color: rgba(234, 51, 35, 1);
    }
}

.cbButton img{
    width: 60%;
}

/* container */
.cbButtonFlexContainer {
    display: flex;
    flex-direction: row;
}



.videoOff {
    border: 3px solid #FFFFFF;  
}

.link{
    color: #4B866E;
    text-decoration: underline;
    cursor: pointer;
}


@media(max-width: 1024px){
    .remoteVideo{
        video{
            max-height: calc(100vh - 14.6vw);
        }
    }
}


.landscape{

    .localVideo {
        
        top: unset;
        right: 150px;
        bottom: 20px;
    
    }

    .remoteVideo{
        width: unset;
        height: 100vh;
        video{
            max-height: 100vh;
        }
    }

    .controlButtonsContainer {
        margin-right: 30px;
        margin-bottom: unset;
        left: unset;
        right: 0px;
        bottom: calc( (100vh - 300px) / 2);
    }

    .joinControlButtonsContainer{
        flex-direction: column;
        height: 330px;
        width: 108px;
    }

    .cbButton, .cbButtonMicrophone, .cbButtonCallInactive {
        margin-left: unset;
        margin-top: 2.5rem;
    }
}