.overlay{
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .content{
        max-width: 90%;
        width: fit-content;
        background-color: white;
        height: fit-content;
        border-radius: 6px;
        &.withPadding{
            padding: 24px 32px;
        }
    }
}