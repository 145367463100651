.container{

    .main {
        background: #FFFFFF;
        border-radius: 20px;
        width: 340px;
        margin-left: calc((100vw - 340px) / 2);
        padding-bottom: 20px;


        .mainTop {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 1rem;
        }

        .mainTop > p, .mainOrOption > p, .mainBottom > p{
            font-family: "Libre Baskerville";
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: .5pt;
            color: #5E5E5E;
        }

        .mainTop > :first-child, .mainOrOption, .mainBottom > :first-child {
            margin-top: 10% !important;
        }

        .mainLogo, .mainLogoText, .mainDownload, .mainBottom  {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .mainLogo {
            margin-top: 4%;
        }

        .mainLogoText > p {
            font-family: "Open Sans",  sans-serif;
            font-weight: 400;   
            font-style: normal;
            font-size: 14px;
            line-height: 22px;
            color: #014D35;
        }


        .mainOrOption {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }

        .mainOrOptionSeparator {
            width: 71px;
            height: 0px;
            border: 1px solid #D5D5D5;
        }
    }
}