/* defined useful constants */
html {
    --aspectRatio16x9: calc(16/9);
}



/* =================================================================
* Media queries to adapt video window size based in device
* ==================================================================
*/

@media (orientation: portrait) {
    @media (max-width: 319px) {

        .headerLogo {
            margin-left: .2rem;
        }
    
        .headerLogo > img {
            width: 100%;
            height: auto;
            min-width: 100px;
        }

        .techSupportContainer {
            margin-right: .2rem;
        }
    }

    @media (min-width: 320px) and (max-width: 539px) {
    
        .headerLogo > img {
            width: 70%;
            height: auto;
        }
    }

    @media (min-width: 540px) and (max-width: 1024px) {
    
        .headerLogo > img {
            width: 50%;
            height: auto;
        }
    }
}