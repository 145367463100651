/*Main Area*/
body {
    margin-top: 0px;
    margin-left: auto;
    margin-right: auto;
    background-color: #F7F7F7;
}

#mainHeader {
    background-color: #FFFFFF;
}

.mainHeaderContainer {
    display: flex;
    align-items: center;
}

.headerLogo {
    flex: 3;
    order: 1;
    margin-left: 1rem;
}


.techSupportPhoneGraphic {
    order: 2;
    flex-basis: 2rem;
}

.techSupportContainer {
    order: 3;
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
}

.techSupportLabel > p {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    text-transform: uppercase;

    color: #4B866E;
}

.techSupportLabel {
    align-self: flex-start;
    margin-bottom: -32px;

}

.techSupportPhone {
    margin-right: .5rem;
    align-items: center;
}

.techSupportPhone > p, .techSupportPhone > a {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    text-transform: uppercase;
    line-height: 22px;
    color: #4B866E;
}

a:hover,  a:visited, a:link,  a:active
{
    text-decoration: none!important;
    color: #4B866E;
}

.techSupportButton, .verticalTechSupportButton {
    background: #4B866E;
    border-radius: 5px;
    border-color: #4B866E;
    border-style: solid;

    width: 9rem;

    text-align: left;

    cursor: pointer
}


.techSupportLabelMobile {
    display: block;
    line-height: 1;

    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: .6rem;
    text-transform: uppercase;
    line-height: 22px;
    color: #FFFFFF;

}

.techSupportNumberMobile {
    display: block;
    line-height: 1;

    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: .8rem;
    text-transform: uppercase;
    line-height: 22px;
    color: #FFFFFF;



}

.techSupportPhoneGraphicMobile {
    float: left;
    margin-top: .5rem;
    padding-right: .5rem;
    
}


.techSupportNumberMobileContainer {
    margin-top: -9px;
}

.verticalTechSupportButton {
    transform: rotate(-90deg);
    margin-top: 17vh;
}