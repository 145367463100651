.container{
    position: fixed;
    z-index: 99999;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0);
    animation: fadein 0.3s linear forwards;
    img{
        position: fixed;
        width: 90px;
        height: 90px;
        left: calc(50% - 40px);
        top: calc(50% - 40px);
        animation: spin 1.5s linear infinite;
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


@keyframes fadein {
    0% { background-color: rgba(255,255,255,0);}
    100% { background-color: rgba(255,255,255,0.8);}
}