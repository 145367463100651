.content{
    font-size: 16px;
    font-weight: 700;
    color: #1E1E20;
    text-align: center;
    max-width: 240px;
    padding: 24px 32px;
}
.button{
    padding: 11px;
    border-top: 1px solid #EBEBED;
    font-size: 18px;
    font-weight: 600;
    color: #4B866E;
    text-align: center;
    cursor: pointer;
    &.negative{
        color: #F03F3F;
        font-weight: 400;
    }
}