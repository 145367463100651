.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    .greeting{
        color: #711766;
        font-weight: 700;
    }
    .footer{
        font-size: 11px;
    }
}