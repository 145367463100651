.processingContainer {
    display:flex;
    flex-direction: column;
    align-items: center;
    h1{
        margin-top: 2rem;
    }
}

.progressContainer {
    display: flex;
    flex-direction: row;
    align-content: space-between;
    margin-top: 4rem;
}

.progressIcon1 {
    width: 8px;
    height: 49px;
    margin: .6rem .2rem;
    background-color: #65135C;
    border-radius: 100px;
}

.progressIcon2 {
    width: 8px;
    height: 49px;
    margin: .1rem .2rem;
    background-color: #EF8B88;
    border-radius: 100px;
}

.progressIcon3 {
    width: 8px;
    height: 49px;
    margin: .6rem .2rem;
    background-color: #8BBFBE;
    border-radius: 100px;
}

.progressIcon4 {
    width: 8px;
    height: 49px;
    margin: 0rem .2rem;
    background-color: #65135C;
    border-radius: 100px;
}

.progressIcon5 {
    width: 8px;
    height: 49px;
    margin: .5rem .2rem;
    background-color: #EF8B88;
    border-radius: 100px;
}

.progressIcon6 {
    width: 8px;
    height: 49px;
    margin: .8rem .2rem;
    background-color: #8BBFBE;
    border-radius: 100px;
}