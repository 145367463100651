.portraitPage{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
}

.landscapePage{
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
}

.verticalHeaderContainer{
    width: 65px;
    display: flex;
    flex-direction: column;
    .verticalTechSupportContainer{
        width: 65px;
        flex: 1;
        button{
            transform-origin: 0% 0%;
            position: absolute;
            left: 10px;
            top: 153px;
            margin-top: unset;
        }
    }

    .verticalHeaderLogo{
        img{
            transform: rotate(-90deg);
            position: absolute;
            bottom: -50px;
            left: 0px;
            max-width: 40vh;
            transform-origin: top left;
            object-fit: contain;
            top: 95vh !important;
        }
        width: 65px;
        flex: 1;
    }
}

.logo{
    image-rendering: smooth;
    image-rendering: optimizeQuality;
    image-rendering: crisp-edges;
    image-rendering: -webkit-optimize-contrast;
}