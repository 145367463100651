.title{
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #39393B;
}
.text{
    margin-top: 11px;
    font-weight: 600;
    font-size: 12px;
    line-height: 15.5px;
    color: #8185A1;
}